import React from "react";

import { useTranslation } from 'react-i18next';
import constants from '../constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const ContactButton = () => {

    const { t } = useTranslation();

    const buttonActions = [
        {
            name: 'Mail',
            link: constants.MAILTO,
            icon: faEnvelope
        },
        // {
        //     name: 'Tel',
        //     link: constants.TEL,
        //     icon: faPhone
        // },
    ];

    return (
        <div className='flex flex-col bg-grey-light rounded-xl dark:bg-grey-dark shadow-md'>
            <div className='flex py-2 justify-center items-center text-text-light dark:text-text-dark text-center text-lg'>
                {t('ContactSales') + ':'}
            </div>
            <div className='flex flex-row pb-4 px-2'>
                {buttonActions.map(a =>
                    <a href={a.link}
                        className='flex flex-grow flex-row h-12 justify-center items-center mx-2 py-2 px-4 bg-blue text-white-light dark:text-white-dark text-center text-base font-semibold shadow-md rounded-lg transition duration-320 ease-in-out transform hover:-translate-y-1 hover:scale-110'>
                        {t(a.name)}
                        <div className='px-2'>
                            <FontAwesomeIcon icon={a.icon} />
                        </div>
                    </a>
                )
                }
            </div>
        </div >
    );
}

export default ContactButton;