import React, {
  useRef,
  // useState 
} from 'react';

import { graphql } from 'gatsby';
// import {
//  GatsbyImage, 
//  getImage, 
//  StaticImage
// } from "gatsby-plugin-image";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, /*faArrowRight*/ } from '@fortawesome/free-solid-svg-icons';

import Layout from '../components/Layout';
// import Modal from '../components/Modal';
import AppButtons from '../components/AppButtons';
import Seo from '../components/Seo';
import ContactButton from '../components/ContactButton';

const IndexPage = ({ data }) => {

  const { t } = useTranslation();

  const featureBeginRef = useRef(null);
  // const [showModal, setShowModal] = useState(false);

  const handleScrollToFeatures = (e) => {
    e.preventDefault();
    window.scrollTo(0, (featureBeginRef.current.offsetTop - 100));
  }

  // const handleShowFeatures = (e) => {
  //   e.preventDefault();
  //   setShowModal(true);
  // }

  // const image = getImage(data.heroImage.childImageSharp.gatsbyImageData);

  return (
    <Layout
      currentMenuItem='index'
    >
      <Seo
        title={t('Pageindex')}
      />
      <div className="mx-auto rounded-xl overflow-hidden md:max-w-5xl md:h-full">
        <div className="md:flex flex-row md:h-full md:items-center justify-around">
          <div className="p-2">
            <img
              className="object-contain max-h-1/2 w-auto"
              src={data.heroImage.publicURL}
              alt=""
            />
            {/* <GatsbyImage
              className=" object-contain max-h-1/2 w-auto"
              image={image}
              alt="" /> */}
          </div>
          <div className="p-8 bg-grey-light dark:bg-grey-dark shadow-md md:shadow-none rounded-xl">
            <div className="text-2xl text-text-light dark:text-text-dark font-bold">
              {t("WelcomeTitle") + " 👋"}
            </div>
            <p className="mt-2 text-text-light dark:text-text-dark">
              {t("WelcomeText")}
            </p>
            <button
              onClick={handleScrollToFeatures}
              type='button'
              className="flex flex-row justify-center items-center py-2 px-4 mt-4 bg-blue text-white-light dark:text-white-dark text-center text-base font-semibold shadow-md rounded-lg transition duration-320 ease-in-out transform hover:-translate-y-1 hover:scale-110">
              {t("ShowFeatures")}
              <div className='px-2 animate-pulse'>
                <FontAwesomeIcon icon={faArrowDown} />
              </div>
            </button>
          </div>
          <div className='md:w-1/3' />
        </div>
      </div>
      {
        data.features.nodes.map((item, index) => {

          // const image = getImage(item.image);

          return (
            <div key={index}>
              {/* <Modal
                showModal={showModal}
                setShowModal={setShowModal}
              >
                <video
                  controls
                  autoPlay
                  muted
                  src={item.video.publicURL}
                  poster={item.image.publicURL}
                />
              </Modal> */}
              <div
                ref={index === 0 ? featureBeginRef : undefined}
                className="md:h-80 mx-auto bg-grey-light dark:bg-grey-dark rounded-xl shadow-md overflow-hidden md:max-w-2xl my-2 md:my-4">
                <div className={(index + 1) % 2 === 0 ? "md:flex md:h-full md:justify-center md:items-center" : "md:flex md:h-full md:justify-center md:items-center flex-row-reverse"}>
                  <div className="md:flex-shrink-0 p-2">
                    {/* <GatsbyImage
                      className="h-48 w-full object-contain md:h-full md:w-48"
                      image={image}
                      alt="" /> */}
                    <img
                      className="h-48 w-full object-contain md:h-full md:w-64"
                      src={item.image.publicURL}
                      alt=""
                    />
                  </div>
                  <div className="p-8 rounded-xl">
                    <div className="text-2xl text-text-light dark:text-text-dark font-bold">
                      {t(item.title) + ' ' + item.emoji}
                    </div>
                    <p className="mt-2 md:text-lg text-text-light dark:text-text-dark">
                      {t(item.text)}
                    </p>
                    {/* <button
                      onClick={handleShowFeatures}
                      type='button'
                      className="flex flex-row justify-center items-center mt-4 bg-transparent text-blue-light dark:text-blue-dark text-center text-lg font-semibold rounded-lg transition duration-320 ease-in-out transform hover:-translate-y-1 hover:scale-110 animate-pulse"
                    >
                      {t("Show")}
                      <div className='px-2'>
                        <FontAwesomeIcon icon={faArrowRight} />
                      </div>
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      }
      <div className='mx-auto md:max-w-2xl'>
        <ContactButton />
      </div>
      <div
        className="mx-auto bg-grey-light dark:bg-grey-dark rounded-xl shadow-md overflow-hidden md:max-w-2xl mt-2 md:mt-4">
        <AppButtons
          headline={t("FreeTry")}
        />
      </div>
    </Layout >
  )
}

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    },
    features:allFeaturesJson {
          nodes {
          emoji
          text
          title
          image {
              publicURL
              childImageSharp {
                  gatsbyImageData
                  }
              }
          }
      },
    heroImage: file(relativePath: {eq: "mockup.png"}) {
    publicURL
    childImageSharp {
      gatsbyImageData
    }
  }
  }
`;